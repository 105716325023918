<template>
    <router-view />
</template>

<script>
export default {
    name: "Home",
}
</script>

<style scoped>

</style>
